<script setup>
import { ref } from 'vue';
import {Head} from '@inertiajs/vue3'
import SubscribersLayout from "../../../Layouts/SubscribersLayout.vue";
import MagicLinkForm from "../../../../_Components/MagicLinkForm.vue";
import { themes } from '../../../../_Themes/themes';

const props = defineProps({
    companyId: String,
});

const themeSelected = ref(themes('clau'));

</script>

<template>
    <Head title="Inicia sesión"/>

    <SubscribersLayout :includeHeader="false" :includeBottomRing="true" :themeSelected="themeSelected">
        <div class="py-5 mx-auto max-w-lg">
            <img src="/checkouts/clau/img/logo.png" alt="clau" class="mx-auto max-w-[180px]">
            <h1 class="font-DMSans text-5xl text-center mt-10">Portal de <span class="text-clau-blue">facturación</span></h1>
            <p class=" text-lg text-center mt-10 mx-6 font-DMSans">
                Le damos la bienvenida al portal de facturación de Clau. Este espacio le permitirá acceder al historial de pagos, añadir su perfil de facturación, generar y descargar facturas. 
            </p>
            <div class="bg-clau-gray-light mt-10 py-14 px-14 mx-auto rounded-[15px]">
                <p class="text-clau-black text-center font-DMSans mb-4">
                    Para obtener el enlace de acceso al portal, por favor ingrese el correo electrónico asociado a su cuenta.
                </p>
                <MagicLinkForm class="mx-5"
                    :company="companyId"
                    classes-input="ring-gray-100 rounded-full font-DMSans"
                    classes-button="bg-clau-blue text-white rounded-full font-DMSans"
                >
                    Obtener link de acceso
                </MagicLinkForm>
            </div>
        </div>
    </SubscribersLayout>
</template>

